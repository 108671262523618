import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import IconsBar from "../../components/IconsBar/IconsBar";
import { ClassroomSelect } from "../../components/ClassroomSelect";
import { PeriodSelect } from "../../components/PeriodSelect";
import {
  getStudentsByClassroom,
  getSubjects,
  setFinalGrades,
} from "../../api/sponteAPI";
import { SubjectSelect } from "../../components/SubjectSelect";
import { GradeItem } from "../../components/GradeItem/GradeItem";
import { Button } from "@mui/material";
import { getGradesByClassroom, updateAllGrades } from "../../api/autenticoAPI";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { AlertDialog } from "../../components/AlertDialog";
import { ToastAlert } from "../../components/ToastAlert";
import {
  Container,
  TitleArea,
  PageTitle,
  SelectionArea,
  SponteArea,
  WarningText,
  SponteButtons,
} from "./styles";
import { CustomAlert } from "../../components/CustomAlert/CustomAlert";
import SelectSys from "../../components/Systech/SelectSys/SelectSys";

const Grades = () => {
  const [anoLetivo, setAnoLetivo] = useState("2025");
  const { user } = React.useContext(AuthContext);
  const [classrooms, setClassrooms] = useState([]);
  const [period, setPeriod] = useState();
  const [subject, setSubject] = useState();
  const [subjects, setSubjects] = useState([]);
  const [studentsByClass, setStudentsByClass] = useState([]);
  const [classGrades, setClassGrades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("error");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [updateStudentVisibility, setUpdateStudentVisibility] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [validate, setValidate] = useState(false);
  //   try {
  //     const promises = [];

  //     classGrades.grades.forEach((grade) => {
  //       promises.push(setPartialGrades(grade, "ac1", grade.ac1));
  //       promises.push(setPartialGrades(grade, "ac2", grade.ac2));
  //     });
  //     await Promise.all(promises);
  //     setMessage("Sistemas atualizados com sucesso!");
  //     setType("success");
  //     setShow(true);
  //   } catch (error) {
  //     setType("error");
  //     setMessage(
  //       "Ocorreu um erro ao atualizar os sistemas. Favor tentar novamente em alguns minutos."
  //     );
  //     setShow(true);
  //     console.log(error);
  //   }
  // };

  const calcMedia = (AC1, AC2) => {
    // Convertendo AC1 e AC2 para números float, considerando vírgula como separador decimal
    const ac1Value = parseFloat((AC1 && AC1.replace(",", ".")) || 0);
    const ac2Value = parseFloat((AC2 && AC2.replace(",", ".")) || 0);

    // Calculando a média
    const partialMedia = (ac1Value + ac2Value) / 2;

    // Formatando o resultado para uma casa decimal com vírgula como separador decimal
    const formattedMedia = partialMedia.toLocaleString("pt-BR", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
    return formattedMedia;
  };
  const sendFinalGrades = async () => {
    try {
      const promises = [];

      classGrades.grades.forEach((grade) => {
        promises.push(setFinalGrades(grade, calcMedia(grade.ac1, grade.ac2)));
      });
      await Promise.all(promises);
      setMessage("Sistemas atualizados com sucesso!");
      setType("success");
      setShow(true);
      setValidate(true);
    } catch (error) {
      setType("error");
      setMessage(
        "Ocorreu um erro ao atualizar os sistemas. Favor tentar novamente em alguns minutos."
      );
      setShow(true);
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (classrooms && classrooms.length > 0) {
      getSubjects(classrooms[1]).then((res) =>
        setSubjects(
          Array.isArray(res.Disciplinas) ? res.Disciplinas : [res.Disciplinas]
        )
      );
      getStudentsByClassroom(classrooms[1]).then((res) =>
        setStudentsByClass(res?.Integrantes)
      );
    }
    setSubject(undefined);
  }, [classrooms]);

  useEffect(() => {
    setLoading(true);
    setSelectAll(false);
    if (period !== undefined && subject !== undefined) {
      getGradesByClassroom(classrooms[1], subject.id, period).then((res) => {
        setClassGrades(res);
        setLoading(false);
      });
    }
    setUpdateStudentVisibility(0);
    console.log(subject);
  }, [classrooms, subject, period]);

  useEffect(() => {
    const setInitialStates = () => {
      setClassGrades([]);
      setPeriod(undefined);
      setSubject(undefined);
    };
    setInitialStates();
  }, [anoLetivo]);

  const repeatGrades = async (value, currentSetter) => {
    let students = studentsByClass.map((res) => res.AlunoID);
    const values = {
      [currentSetter]: value,
    };
    setLoading(true);
    const promises = students.map((item) =>
      updateAllGrades(item, classrooms[1], subject.id, period, values)
    );
    try {
      await Promise.all(promises);
      const updatedGrades = await getGradesByClassroom(
        classrooms[1],
        subject.id,
        period
      );
      setClassGrades(updatedGrades);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao atualizar notas:", error);
    } finally {
      setTimeout(() => {
        const inputFields = document.querySelectorAll(".grade-input");
        inputFields.forEach((input) => input.focus());
        const inputFinish = document.querySelector(".finish");
        inputFinish.focus();
      }, 1000);
    }
  };

  return (
    <Container>
      <Header />
      <IconsBar />
      <TitleArea>
        <PageTitle>Sistema de Avaliações</PageTitle>
        <SelectSys anoLetivo={anoLetivo} setAnoLetivo={setAnoLetivo} />
      </TitleArea>
      <SelectionArea>
        <ClassroomSelect
          anoLetivo={anoLetivo}
          classrooms={classrooms}
          setClassrooms={setClassrooms}
        />
        <PeriodSelect period={period} setPeriod={setPeriod} />
        <SubjectSelect
          subject={subject}
          setSubject={setSubject}
          subjects={subjects}
        />
      </SelectionArea>

      {studentsByClass &&
        studentsByClass.length > 0 &&
        period !== undefined &&
        subject !== undefined &&
        !loading &&
        studentsByClass.map((student, index) => (
          <GradeItem
            key={student.AlunoID}
            aluno={{
              nome: `${student.NumeroChamada} - (${student.AlunoID}) ${student.Nome}`,
              AlunoID: student.AlunoID,
            }}
            legend={index === 0}
            tabIndex={index + 13}
            last={studentsByClass.length}
            TurmaID={classrooms[1]}
            DisciplinaID={subject.id}
            nomeDisciplina={subject.name}
            period={period}
            classGrades={classGrades}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            updateStudentVisibility={updateStudentVisibility}
            repeatGrades={repeatGrades}
            validate={validate}
            setValidate={setValidate}
          />
        ))}

      {["admin", "coordinator"].includes(user.level) &&
        classrooms.length > 0 &&
        period !== undefined &&
        subject !== undefined && (
          <SponteArea>
            <ToastAlert
              show={show}
              setShow={setShow}
              type={type}
              message={message}
            />
            <SponteButtons>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  setUpdateStudentVisibility(true);
                  setInfoMessage("Visibilidades alteradas com sucesso!");
                  setShowMessage(true);
                }}
              >
                Atualizar Visibilidade
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => setOpen(true)}
              >
                CALCULAR NOTAS FINAIS
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => setValidate(true)}
              >
                VERIFICAR MÉDIAS
              </Button>
            </SponteButtons>
            <AlertDialog
              open={open}
              setOpen={setOpen}
              title="Confirmação de alteração entre sistemas!"
              body={
                "Você confirma o envio de todos os dados de notas ao Sponte?"
              }
              handleConfirm={async () => {
                try {
                  await sendFinalGrades();
                } catch (error) {
                  setType("error");
                  setMessage(
                    "Ocorreu um erro ao atualizar os sistemas. Favor tentar novamente em alguns minutos."
                  );
                  setShow(true);
                  console.log(error);
                }
              }}
            />
            <WarningText>
              {`OBS: Ao clicar no botão "ATUALIZAR VISIBILIDADE" serão modificadas
              as visibilidades das notas no aplicativo de acordo com as caixas
              selecionadas. 
              Ao clicar no botão "CALCULAR NOTAS FINAIS" o campo "Média" será submetido ao sponte de forma imediata.
              Ao clicar no botão "VERIFICAR MÉDIAS" o sistema vai comparar se as médias do sponte correspondem as médias calculadas no aplicativo.`}
            </WarningText>
            <CustomAlert
              infoType="success"
              status={showMessage}
              setStatus={setShowMessage}
              infoMessage={infoMessage}
            />
          </SponteArea>
        )}
    </Container>
  );
};

export default Grades;
