import React, { useState } from "react";
import html2canvas from "html2canvas";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Tabs,
  Tab,
  Grid,
  IconButton,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import {
  SaveAlt as SaveAltIcon,
  DateRange as DateRangeIcon,
  School as SchoolIcon,
  Visibility as VisibilityIcon,
  ContentCopy as ContentCopyIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import backgroundImage from "./frame_agenda.png";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
}));

const StyledTab = styled(Tab)({
  minWidth: "60px",
  flex: 1,
  minHeight: "48px",
  padding: "6px 12px",
});

const AgendaForm = ({ onImageGenerate }) => {
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [agendaData, setAgendaData] = useState({
    date: getCurrentDate(),
    entries: [
      {
        aula: "1ª AULA",
        disciplina: "",
        conteudo: "",
        atividade: "",
        casa: "",
      },
      {
        aula: "2ª AULA",
        disciplina: "",
        conteudo: "",
        atividade: "",
        casa: "",
      },
      {
        aula: "3ª AULA",
        disciplina: "",
        conteudo: "",
        atividade: "",
        casa: "",
      },
      {
        aula: "4ª AULA",
        disciplina: "",
        conteudo: "",
        atividade: "",
        casa: "",
      },
      {
        aula: "5ª AULA",
        disciplina: "",
        conteudo: "",
        atividade: "",
        casa: "",
      },
      {
        aula: "6ª AULA",
        disciplina: "",
        conteudo: "",
        atividade: "",
        casa: "",
      },
    ],
  });

  const [activeTab, setActiveTab] = useState(0);
  const [showPreview, setShowPreview] = useState(false);

  const handleEntryChange = (index, field, value) => {
    const newEntries = [...agendaData.entries];
    newEntries[index] = { ...newEntries[index], [field]: value };
    setAgendaData({ ...agendaData, entries: newEntries });
  };

  const handleCopyPreviousClass = () => {
    if (activeTab > 0) {
      const previousEntry = agendaData.entries[activeTab - 1];
      const newEntries = [...agendaData.entries];
      newEntries[activeTab] = {
        ...newEntries[activeTab],
        disciplina: previousEntry.disciplina,
        conteudo: previousEntry.conteudo,
        atividade: previousEntry.atividade,
        casa: previousEntry.casa,
      };
      setAgendaData({ ...agendaData, entries: newEntries });
    }
  };

  const handleDateChange = (e) => {
    setAgendaData({ ...agendaData, date: e.target.value });
  };

  const handleGenerateImage = async () => {
    try {
      const preview = document.getElementById("agenda-preview");
      if (!preview) return;

      const canvas = await html2canvas(preview, {
        scale: 2,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
        logging: true,
        width: preview.offsetWidth,
        height: preview.offsetHeight,
      });

      canvas.toBlob(
        (blob) => {
          const imageFile = new File(
            [blob],
            `agenda-${agendaData.date || "sem-data"}.png`,
            { type: "image/png" }
          );
          if (onImageGenerate) {
            onImageGenerate(imageFile, agendaData.date);
          }
        },
        "image/png",
        1.0
      );
      setShowPreview(false);
    } catch (error) {
      console.error("Erro ao gerar imagem:", error);
      alert("Erro ao gerar imagem. Por favor, tente novamente.");
    }
  };

  const PreviewContent = () => (
    <div
      id="agenda-preview"
      style={{
        position: "relative",
        width: "595px",
        height: "842px",
        margin: "0 auto",
        backgroundColor: "#ffffff",
      }}
    >
      <img
        src={backgroundImage}
        alt="Background"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />

      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "95%",
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          padding: "1px",
          borderRadius: "4px",
        }}
      >
        <Typography
          align="center"
          sx={{ fontWeight: "bold", fontSize: "0.9rem", mb: 0.5 }}
        >
          AGENDA DIÁRIA -{" "}
          {new Date(agendaData.date + "T00:00:00").toLocaleDateString("pt-BR")}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.2 }}>
          {agendaData.entries
            .filter(
              (entry) =>
                entry.disciplina ||
                entry.conteudo ||
                entry.atividade ||
                entry.casa
            )
            .map((entry, index) => (
              <Paper
                key={index}
                elevation={1}
                sx={{
                  p: 1,
                  mb: 0.5,
                  borderRadius: 1,
                  bgcolor: "grey.50",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 0.5,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                    {entry.aula}
                  </Typography>
                  <Typography color="primary" sx={{ fontSize: "0.8rem" }}>
                    {entry.disciplina}
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                >
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "medium",
                        color: "text.secondary",
                        fontSize: "0.8rem",
                        flexShrink: 0,
                      }}
                    >
                      Conteúdo:
                    </Typography>
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {entry.conteudo || "-"}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography
                      sx={{
                        fontWeight: "medium",
                        color: "text.secondary",
                        fontSize: "0.8rem",
                        flexShrink: 0,
                      }}
                    >
                      Atividade em Sala:
                    </Typography>
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {entry.atividade || "-"}
                    </Typography>
                  </Box>

                  {entry.casa && (
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "medium",
                          color: "text.secondary",
                          fontSize: "0.8rem",
                          flexShrink: 0,
                        }}
                      >
                        Atividade para Casa:
                      </Typography>
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        {entry.casa}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Paper>
            ))}
        </Box>
      </div>
    </div>
  );

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <StyledPaper elevation={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center" gap={1}>
              <SchoolIcon color="primary" sx={{ fontSize: 20 }} />
              <TextField
                type="date"
                size="small"
                value={agendaData.date}
                onChange={handleDateChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <DateRangeIcon
                      color="action"
                      sx={{ mr: 1, fontSize: 20 }}
                    />
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stepper
              activeStep={activeTab}
              alternativeLabel
              sx={{ minHeight: "auto" }}
            >
              {agendaData.entries.map((entry, index) => (
                <Step key={index}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": { fontSize: "0.75rem" },
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                    }}
                  >
                    {entry.aula}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper>
        <Box mb={2}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {agendaData.entries.map((_, index) => (
              <StyledTab key={index} label={`${index + 1}ª Aula`} />
            ))}
          </Tabs>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {activeTab > 0 && (
              <Box display="flex" justifyContent="flex-end" mb={1}>
                <Tooltip title="Copiar dados da aula anterior">
                  <IconButton
                    onClick={handleCopyPreviousClass}
                    color="primary"
                    size="small"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Disciplina"
              value={agendaData.entries[activeTab].disciplina}
              onChange={(e) =>
                handleEntryChange(activeTab, "disciplina", e.target.value)
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Conteúdo"
              value={agendaData.entries[activeTab].conteudo}
              onChange={(e) =>
                handleEntryChange(activeTab, "conteudo", e.target.value)
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Atividade em Sala"
              value={agendaData.entries[activeTab].atividade}
              onChange={(e) =>
                handleEntryChange(activeTab, "atividade", e.target.value)
              }
              variant="outlined"
              multiline
              rows={2}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Atividade para Casa"
              value={agendaData.entries[activeTab].casa}
              onChange={(e) =>
                handleEntryChange(activeTab, "casa", e.target.value)
              }
              variant="outlined"
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
      </StyledPaper>

      <Box display="flex" justifyContent="center" gap={2} my={2}>
        <Button
          variant="outlined"
          size="medium"
          startIcon={<VisibilityIcon />}
          onClick={() => setShowPreview(true)}
        >
          Visualizar
        </Button>
      </Box>

      <Dialog
        open={showPreview}
        onClose={() => setShowPreview(false)}
        maxWidth={false}
        PaperProps={{
          style: {
            width: "595px",
            height: "auto",
            maxHeight: "90vh",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Preview da Agenda
          <IconButton onClick={() => setShowPreview(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <PreviewContent />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPreview(false)}>Fechar</Button>
          <Button
            onClick={handleGenerateImage}
            variant="contained"
            startIcon={<SaveAltIcon />}
          >
            Gerar Imagem
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AgendaForm;
