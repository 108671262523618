export const sendOneSignalNotifications = ({ name, unit, ids, message }) => {
  const requestBody = {
    app_id: "31860986-7f49-44ac-8552-84fd749ee8fb",
    headings: { en: name },
    contents: { en: message },
    filters: [
      {
        field: "tag",
        key: "idSponte",
        relation: "=",
        value: ids[0].toString(),
      },
      {
        field: "tag",
        key: "unit",
        relation: "=",
        value: unit,
      },
    ],
    isAnyWeb: false,
    isAndroid: true,
    isIos: true,
  };

  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      Authorization: "Basic OTg5NzU1NGUtZGNhYS00NWQ1LThmNGItYWY0MmUxZmIwOTFi",
      "content-type": "application/json",
    },
    body: JSON.stringify(requestBody),
  };

  return fetch("https://onesignal.com/api/v1/notifications", options)
    .then((response) => response.json())
    .then((response) => {
      console.log("OneSignal Response:", response);
      if (response.errors || response.error) {
        throw new Error(JSON.stringify(response.errors || response.error));
      }
      return response;
    })
    .catch((err) => {
      console.error("OneSignal Error:", err);
      throw err;
    });
};
