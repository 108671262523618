import React, { useEffect, useState, useCallback, useContext } from "react";
import { Button, TextField } from "@mui/material";
import { styled } from "@material-ui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LinkIcon from "@mui/icons-material/Link";
import RefreshIcon from "@mui/icons-material/Refresh";
import SendIcon from "@mui/icons-material/Send";

// Componentes locais
import Header from "../../components/Header";
import IconsBar from "../../components/IconsBar/IconsBar";
import { MultipleClassroomSelect } from "../../components/MultipleClassroomSelect";
import ImageSkeleton from "./ImageSkeleton";
import { Loading } from "../../components/Loading/Loading";
import { AlertMessages } from "../../components/AlertMessages";

// API e serviços
import { sendOneSignalAnnouncements } from "../../api/autenticoAPI";
import { getAllClassrooms } from "../../api/sponteAPI";
import { sendOneSignalNotifications } from "../../services/oneSignal";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { anoLetivo, getOneSignalUsers } from "../../utils/YEARCONSTS";

// Estilos
import {
  ActionsArea,
  AttachmentsArea,
  Container,
  ContentArea,
  DividerAreaLeft,
  DividerAreaRight,
  FormItens,
  PageTitle,
  UsersInfo,
} from "./styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Announcements = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [anexosSelecionados, setAnexosSelecionados] = useState([]);
  const [linksInseridos, setLinksInseridos] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [title, setTitle] = useState("");
  const [usersNotifications, setUsersNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [typeMessage, setTypeMessage] = useState("success");
  const [contentMessage, setContentMessage] = useState("teste");
  const [classroomsName, setClassroomsName] = useState([]);

  const auth = useContext(AuthContext);

  const resetFields = useCallback(() => {
    setClassrooms([]);
    setTitle("");
    setUsersNotifications([]);
    setSelectedImage("");
    setAnexosSelecionados([]);
    setLinksInseridos([]);
  }, []);

  useEffect(() => {
    if (classrooms.length > 0 && !classrooms[0]?.hasOwnProperty("TurmaID")) {
      setUsersNotifications([]);
    }
  }, [classrooms]);

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const res = await getAllClassrooms(anoLetivo);
        setClassrooms(res);
        setClassroomsName(res);
      } catch (error) {
        console.error("Erro ao carregar salas:", error);
        setTypeMessage("error");
        setContentMessage("Erro ao carregar salas");
        setShow(true);
      }
    };

    fetchClassrooms();
  }, []);

  const getUsers = useCallback(async () => {
    try {
      setLoading(true);

      if (classrooms[0]?.hasOwnProperty("TurmaID")) {
        const users = classrooms.map((item) => item.TurmaID);
        const oneSignalUsers = await getOneSignalUsers(users);
        const flattenedUsers = oneSignalUsers.flatMap((user) => user.users);
        setUsersNotifications(flattenedUsers);
        setClassrooms(users);
      } else {
        const oneSignalUsers = await getOneSignalUsers(classrooms);
        const filteredUsers = oneSignalUsers.flatMap((user) => user.users);
        setUsersNotifications(filteredUsers);
      }
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      setTypeMessage("error");
      setContentMessage("Erro ao buscar usuários");
      setShow(true);
    } finally {
      setLoading(false);
    }
  }, [classrooms]);

  const sendFile = useCallback(() => {
    const batchSize = 10;
    let currentIndex = 0;

    const sendBatch = () => {
      const batch = classrooms.slice(currentIndex, currentIndex + batchSize);
      currentIndex += batchSize;

      if (batch.length > 0) {
        batch.forEach((classroom) => {
          const className = classroomsName.find(
            (item) => item.TurmaID === classroom
          );
          const fData = new FormData();
          fData.append("announcement", selectedImage);
          anexosSelecionados.forEach((file) => {
            fData.append("attachments", file);
          });
          linksInseridos.forEach((link) => {
            fData.append("links", link);
          });

          fData.append("title", title);
          fData.append("classroom_id", classroom);
          fData.append("classroom_name", className.Nome);
          sendOneSignalAnnouncements(fData);
        });

        setTimeout(sendBatch, 1000);
      }
    };

    try {
      sendBatch();

      console.log("Dados para envio OneSignal:", {
        title,
        unit: auth.user.unit,
        usersNotifications,
        message: "Novo comunicado do Colégio Autêntico!",
      });

      sendOneSignalNotifications({
        name: title,
        unit: auth.user.unit,
        ids: usersNotifications,
        message: "Novo comunicado do Colégio Autêntico!",
      });

      setContentMessage("Comunicados enviados com sucesso!");
      setTypeMessage("success");
      setShow(true);
      resetFields();
    } catch (error) {
      console.error("Erro ao enviar comunicados:", error);
      setTypeMessage("error");
      setContentMessage("Erro ao enviar comunicados");
      setShow(true);
    }
  }, [
    classrooms,
    classroomsName,
    selectedImage,
    anexosSelecionados,
    linksInseridos,
    title,
    auth.user.unit,
    usersNotifications,
    resetFields,
  ]);

  const handleFileChange = useCallback((e) => {
    const files = Array.from(e.target.files || []);
    setAnexosSelecionados((prev) => [...prev, ...files]);
  }, []);

  const handleLinkInsert = useCallback(() => {
    const link = prompt("Insira o link desejado:");
    if (link?.trim()) {
      setLinksInseridos((prev) => [...prev, link.trim()]);
    }
  }, []);

  return (
    <Container>
      <Header />
      <IconsBar />
      {loading && <Loading />}
      <ContentArea>
        <PageTitle>Comunicados</PageTitle>
        <MultipleClassroomSelect
          classrooms={classrooms}
          setClassrooms={setClassrooms}
        />
        <FormItens encType="multipart/form-data">
          <TextField
            value={title}
            onChange={({ target }) => setTitle(target.value.toUpperCase())}
            label="Título do Comunicado"
            style={{ width: "85%", marginBottom: 10 }}
            variant="outlined"
            autoComplete="off"
          />
          <ActionsArea>
            <DividerAreaLeft>
              <ImageSkeleton file={selectedImage} />
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                style={{ backgroundColor: "#1E958C" }}
              >
                Carregar Arquivo
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => setSelectedImage(e.target.files[0])}
                />
              </Button>
            </DividerAreaLeft>
            <DividerAreaRight>
              <Button
                onClick={getUsers}
                component="label"
                variant="contained"
                startIcon={<RefreshIcon />}
                style={{ backgroundColor: "#1E958C" }}
              >
                Carregar destinatários
              </Button>
              {usersNotifications.length > 0 ? (
                <UsersInfo>
                  Existem {usersNotifications.length} usuários aptos a receber
                  esse comunicado!
                </UsersInfo>
              ) : (
                <UsersInfo>
                  Não existem usuários aptos a receber esse comunicado, ou
                  nenhuma turma foi selecionada!
                </UsersInfo>
              )}
              <AttachmentsArea>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  style={{ backgroundColor: "#1E958C" }}
                >
                  Inserir Anexos
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                  />
                </Button>
                <div>
                  {anexosSelecionados.map((file, index) => (
                    <p key={`${file.name}-${index}`}>{file.name}</p>
                  ))}
                </div>
                <Button
                  onClick={handleLinkInsert}
                  component="label"
                  variant="contained"
                  startIcon={<LinkIcon />}
                  style={{ backgroundColor: "#1E958C" }}
                >
                  Inserir Links
                </Button>
                <div>
                  {linksInseridos.map((link, index) => (
                    <p key={`${link}-${index}`}>{link}</p>
                  ))}
                </div>
              </AttachmentsArea>
              <Button
                onClick={sendFile}
                startIcon={<SendIcon />}
                variant="contained"
                style={{ height: 70, width: "80%" }}
                disabled={usersNotifications.length === 0}
              >
                Enviar Comunicados
              </Button>
            </DividerAreaRight>
          </ActionsArea>
        </FormItens>
      </ContentArea>
      <AlertMessages
        show={show}
        typeMessage={typeMessage}
        contentMessage={contentMessage}
        setShow={setShow}
      />
    </Container>
  );
};

export default Announcements;
