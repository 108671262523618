import React, { useContext, useState } from "react";
import {
  AreaText,
  ButtonsArea,
  CardsArea,
  Container,
  IconsArea,
  NameArea,
  StudentName,
} from "./styles";
import { ReactComponent as AddIcon } from "../../assets/add_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/edit_icon.svg";
import { ReactComponent as OkIcon } from "../../assets/ok_icon.svg";
import { ReactComponent as WaitIcon } from "../../assets/wait_icon.svg";
import { ActionButton } from "../../styles/GlobalStyles";
import { CustomModal } from "../CustomModal/CustomModal";
import { Foods } from "../ModalIntegranteParts/Foods";
import { Form } from "../ModalIntegranteParts/Form";
import { Cleaning } from "../ModalIntegranteParts/Cleaning";
import { Button } from "@mui/material";
import { addRoutine, editRoutine } from "../../api/autenticoAPI";
import { foods_campina, foods_patos } from "../../utils/YEARCONSTS";
import { Textarea } from "@mui/joy";
import { CustomAlert } from "../CustomAlert/CustomAlert";
import BoxViewer from "../BoxViewer";
import { AuthContext } from "../../contexts/Auth/AuthContext";

export const IntegranteItem = ({
  student,
  status,
  date,
  editData,
  upadateList,
  setUpdateList,
}) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [entryHours, setEntryHours] = useState(
    status ? editData.sleep.entryHours : 11
  );
  const [entryMinutes, setEntryMinutes] = useState(
    status ? editData.sleep.entryMinutes : 30
  );
  const [exitHours, setExitHours] = useState(
    status ? editData.sleep.exitHours : 12
  );
  const [exitMinutes, setExitMinutes] = useState(
    status ? editData.sleep.exitMinutes : 30
  );
  const [breakfast, setBreakfast] = useState(
    status ? editData.breakfast.foods : []
  );
  const [qtdBreakfast, setQtdBreakfast] = useState(
    status ? editData.breakfast.quantity : false
  );
  const [unavaibleBreakfast, setUnavailableBreakfast] = useState(
    status ? editData.breakfast.unavailable : true
  );
  const [lunch, setLunch] = useState(status ? editData.lunch.foods : []);
  const [qtdLunch, setQtdLunch] = useState(
    status ? editData.lunch.quantity : false
  );
  const [unavailableLunch, setUnavailableLunch] = useState(
    status ? editData.lunch.unavailable : true
  );
  const [snack, setSnack] = useState(status ? editData.snack.foods : []);
  const [qtdSnack, setQtdSnack] = useState(
    status ? editData.snack.quantity : false
  );
  const [unavaibleSnack, setUnavailableSnack] = useState(
    status ? editData.snack.unavailable : true
  );
  const [morningUniform, setMorningUniform] = useState(
    status ? editData.full_uniform.morning : "positive"
  );
  const [lateUniform, setLateUniform] = useState(
    status ? editData.full_uniform.afternoon : "positive"
  );
  const [tomouBanho, setTomouBanho] = useState(
    status ? editData.cleaning.shower : "negative"
  );
  const [lavouCabeca, setLavouCabeca] = useState(
    status ? editData.cleaning.wash_head : "negative"
  );
  const [materialPessoal, setMaterialPessoal] = useState(
    status ? editData.cleaning.supplies : "positive"
  );
  const [infoFamilia, setInfoFamilia] = useState(
    status ? editData.cleaning.info_family : "negative"
  );
  const [courseware, setCourseware] = useState(
    status ? editData.courseware.full : "positive"
  );
  const [homeWork, setHomeWork] = useState(
    status ? editData.courseware.home_work : "positive"
  );
  const [arrivalTime, setArrivalTime] = useState(
    status ? editData.sleep.arrive_on_time : "positive"
  );
  const [sleptSchool, setSleptSchool] = useState(
    status ? editData.sleep.sleep_at_school : "negative"
  );
  const [pee, setPee] = useState(status ? editData.cleaning.pee : 0);
  const [evacuate, setEvacuate] = useState(
    status ? editData.cleaning.evacuate : 0
  );
  const [fraud, setFraud] = useState(status ? editData.cleaning.fraud : 0);
  const [generalComments, setGeneralComments] = useState(
    status ? editData.general_comments : ""
  );
  const [showMessage, setShowMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [listViewers, setListViewers] = useState("");
  const viewed = status && editData.viewers.length > 0 ? true : false;
  const { user } = useContext(AuthContext);

  const handleClickViewers = (event) => {
    const listOfViewers = editData.viewers
      .map((item) => item.parentesco)
      .join(" - ");
    setListViewers(listOfViewers);
    setAnchorEl(event.currentTarget);
  };

  const alterarRotina = () => {
    const routine = {
      id: editData.id,
      breakfast: {
        foods: breakfast,
        quantity: qtdBreakfast,
        unavailable: unavaibleBreakfast,
      },
      lunch: {
        foods: lunch,
        quantity: qtdLunch,
        unavailable: unavailableLunch,
      },
      snack: {
        foods: snack,
        quantity: qtdSnack,
        unavailable: unavaibleSnack,
      },
      cleaning: {
        fraud,
        evacuate,
        pee,
        shower: tomouBanho,
        wash_head: lavouCabeca,
        supplies: materialPessoal,
        info_family: infoFamilia,
      },
      sleep: {
        arrive_on_time: arrivalTime,
        sleep_at_school: sleptSchool,
      },
      full_uniform: {
        morning: morningUniform,
        afternoon: lateUniform,
      },
      courseware: {
        full: courseware,
        home_work: homeWork,
      },
      general_comments: generalComments,
    };
    editRoutine(routine);
    setInfoMessage("Rotina alterada com sucesso!");
    setShowMessage(true);
    setModalStatus(false);
    setUpdateList(!upadateList);
  };
  const handleEditClick = () => {
    setModalStatus(true);
  };

  const salvarRotina = () => {
    const newRoutine = {
      date,
      idSponte: student.AlunoID,
      breakfast: {
        foods: breakfast,
        quantity: qtdBreakfast,
        unavailable: unavaibleBreakfast,
      },
      lunch: {
        foods: lunch,
        quantity: qtdLunch,
        unavailable: unavailableLunch,
      },
      snack: {
        foods: snack,
        quantity: qtdSnack,
        unavailable: unavaibleSnack,
      },
      cleaning: {
        fraud,
        evacuate,
        pee,
        shower: tomouBanho,
        wash_head: lavouCabeca,
        supplies: materialPessoal,
        info_family: infoFamilia,
      },
      sleep: {
        arrive_on_time: arrivalTime,
        sleep_at_school: sleptSchool,
      },
      full_uniform: {
        morning: morningUniform,
        afternoon: lateUniform,
      },
      courseware: {
        full: courseware,
        home_work: homeWork,
      },
      viewers: [],
      general_comments: generalComments,
    };
    addRoutine(newRoutine);
    setInfoMessage("Rotina adicionada com sucesso!");
    setShowMessage(true);
    setModalStatus(false);
    setUpdateList(!upadateList);
  };
  return (
    <Container>
      <NameArea>
        <StudentName>{student.NumeroChamada + "- " + student.Nome}</StudentName>
      </NameArea>
      <IconsArea>
        {!status ? (
          <ActionButton onClick={() => setModalStatus(true)}>
            <AddIcon width={25} height={25} />
          </ActionButton>
        ) : (
          <ActionButton>
            <EditIcon width={30} height={30} onClick={handleEditClick} />
          </ActionButton>
        )}
        {status && viewed ? (
          <ActionButton>
            <OkIcon width={30} height={30} onClick={handleClickViewers} />
          </ActionButton>
        ) : (
          <ActionButton>
            <WaitIcon width={25} height={25} />
          </ActionButton>
        )}
      </IconsArea>
      <CustomModal
        status={modalStatus}
        setStatus={setModalStatus}
        title={student.Nome}
      >
        <CardsArea>
          <Foods
            title={"Lanche da Manhã"}
            foods={
              user.unit === "PATOS"
                ? foods_patos.breakfast.foods
                : foods_campina.breakfast.foods
            }
            selection={breakfast}
            setSelection={setBreakfast}
            qtdFood={qtdBreakfast}
            setQtdFood={setQtdBreakfast}
            unavailable={unavaibleBreakfast}
            setUnavailable={setUnavailableBreakfast}
          />
          <Foods
            title={"Almoço"}
            foods={
              user.unit === "PATOS"
                ? foods_patos.lunch.foods
                : foods_campina.lunch.foods
            }
            selection={lunch}
            setSelection={setLunch}
            qtdFood={qtdLunch}
            setQtdFood={setQtdLunch}
            unavailable={unavailableLunch}
            setUnavailable={setUnavailableLunch}
          />
          <Foods
            title={"Lanche da Tarde"}
            foods={
              user.unit === "PATOS"
                ? foods_patos.snack.foods
                : foods_campina.snack.foods
            }
            selection={snack}
            setSelection={setSnack}
            qtdFood={qtdSnack}
            setQtdFood={setQtdSnack}
            unavailable={unavaibleSnack}
            setUnavailable={setUnavailableSnack}
          />
          <Cleaning
            title={"Higienização e Fisiológico"}
            question1={"Tomou banho na escola?"}
            option1={tomouBanho}
            setOption1={setTomouBanho}
            question2={"Lavou a Cabeça?"}
            option2={lavouCabeca}
            setOption2={setLavouCabeca}
            question3={"Material de higiene pessoal?"}
            option3={materialPessoal}
            setOption3={setMaterialPessoal}
            question4={"Família foi informada?"}
            option4={infoFamilia}
            setOption4={setInfoFamilia}
            positiveTitle={"Sim"}
            negativeTitle={"Não"}
            pee={pee}
            setPee={setPee}
            evacuate={evacuate}
            setEvacuate={setEvacuate}
            fraud={fraud}
            setFraud={setFraud}
          />
          <Form
            title={"Fardamento no Horário Integral"}
            question1={"Manhã"}
            option1={morningUniform}
            setOption1={setMorningUniform}
            question2={"Tarde"}
            option2={lateUniform}
            setOption2={setLateUniform}
            positiveTitle={"Completo"}
            negativeTitle={"Incompleto"}
          />
          <Form
            title={"Material Didático / Pedagógico"}
            question1={"Estava com todo o material necessário?"}
            option1={courseware}
            setOption1={setCourseware}
            question2={"Atividades de casa realizadas?"}
            option2={homeWork}
            setOption2={setHomeWork}
            positiveTitle={"Sim"}
            negativeTitle={"Não"}
          />
          <Form
            title={"Horário de Chegada-Saida / Sono"}
            question1={"Chegou no Horário programado?"}
            option1={arrivalTime}
            setOption1={setArrivalTime}
            question2={"Dormiu na escola?"}
            option2={sleptSchool}
            setOption2={setSleptSchool}
            positiveTitle={"Sim"}
            negativeTitle={"Não"}
            sleepTime={true}
            entryHours={entryHours}
            setEntryHours={setEntryHours}
            entryMinutes={entryMinutes}
            setEntryMinutes={setEntryMinutes}
            exitHours={exitHours}
            setExitHours={setExitHours}
            exitMinutes={exitMinutes}
            setExitMinutes={setExitMinutes}
          />

          <AreaText>
            <Textarea
              maxRows={3}
              minRows={3}
              placeholder="Observações gerais"
              value={generalComments}
              onChange={(e) => setGeneralComments(e.target.value)}
            />
          </AreaText>

          <ButtonsArea>
            {status ? (
              <Button
                onClick={() => {
                  alterarRotina();
                }}
                variant="contained"
              >
                Alterar Rotina
              </Button>
            ) : (
              <Button
                onClick={() => {
                  salvarRotina();
                }}
                variant="contained"
              >
                Salvar Rotina
              </Button>
            )}
          </ButtonsArea>
        </CardsArea>
      </CustomModal>
      <CustomAlert
        infoType="success"
        status={showMessage}
        setStatus={setShowMessage}
        infoMessage={infoMessage}
      />
      <BoxViewer
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        viewers={listViewers}
      />
    </Container>
  );
};
